var map;
var markers = [];
var markerCluster;
var updatedLocations = locations;
var activeInfoWindow;
var key = "all";
var filterWithOrganicFarms = false;
var disabledFarms = [];
var virtualFarms = [];

function mapFilter(filterKey, locationType, e) {
    key = filterKey;
    var updatedLocations = locations;
    var lookupIndex = 9;

    if (jQuery("#" + key).hasClass("search-icon-button-active") && locationType != undefined) {
        jQuery("#" + key).removeClass("search-icon-button-active");
        key = "all";
        //  jQuery("#chkOrganic").prop("disabled", true);
        jQuery("#chkOrganic").prop("checked", false);
        filterWithOrganicFarms = false;
    }
    else {
        jQuery(".search-icon-button").removeClass("search-icon-button-active");
        jQuery("#" + key).addClass("search-icon-button-active");

        //jQuery("#chkOrganic").prop("checked", false);
        // filterWithOrganicFarms = true;

        if (filterKey == "milk" || filterKey == "pig" || filterKey == "other") {
            //  jQuery("#chkOrganic").prop("disabled", false);
        }
        else {
            // jQuery("#chkOrganic").prop("disabled", true);
            // jQuery("#chkOrganic").prop("checked", false);
            //   filterWithOrganicFarms = "";
        }

    }

    if (typeof activeInfoWindow == "object") {
        activeInfoWindow.close();
    }


    for (var i = 0; i < updatedLocations.length; i++) {
        if (virtualFarms.length == 0) {
            markers[i].setVisible(false);
            var isOrganic = Boolean(updatedLocations[i][8]);
            // console.log(key);
            for (var j = 0; j < key.length; j++) {
                if (filterWithOrganicFarms && updatedLocations[i][lookupIndex].toLowerCase().indexOf(key) >= 0) {
                    if (isOrganic) {
                        markers[i].setVisible(true);
                    }
                }
                else if (updatedLocations[i][lookupIndex].toLowerCase().indexOf(key) >= 0) {
                    markers[i].setVisible(true);

                }
                else if (key == "all" || key == "") {
                    markers[i].setVisible(true);
                }
            }

            if ((key == "all" || key == "") && locationType != undefined) {
                markers[i].setVisible(true);
            }
        } else {
            if (IsVartualFarm(updatedLocations[i][11])) {
                markers[i].setVisible(false);
                var isOrganic = Boolean(updatedLocations[i][8]);
                // console.log(key);
                for (var j = 0; j < key.length; j++) {
                    if (filterWithOrganicFarms && updatedLocations[i][lookupIndex].toLowerCase().indexOf(key) >= 0) {
                        if (isOrganic) {
                            markers[i].setVisible(true);
                        }
                    }
                    else if (updatedLocations[i][lookupIndex].toLowerCase().indexOf(key) >= 0) {
                        markers[i].setVisible(true);

                    }
                    else if (key == "all" || key == "") {
                        markers[i].setVisible(true);
                    }
                }

                if ((key == "all" || key == "") && locationType != undefined) {
                    markers[i].setVisible(true);
                }
            }
            else {
                markers[i].setVisible(false);
            }
        }
    }
}

function IsVartualFarm(mmsId) {
    var flag = false;;
    for (var i = 0; i < virtualFarms.length; i++) {
        if (parseInt(mmsId) == virtualFarms[i]) {
            flag = true;
            return flag;
            break;
        }

    }

    return flag;
}



function filterOrganic(chk) {

    GetVirtualFarm(document.getElementById('chkVirtual').checked);

    if (chk == true) {
        filterWithOrganicFarms = true;
        mapFilter(key);
    }
    else {
        filterWithOrganicFarms = false;
        mapFilter(key);
    }
}


function filterVirtual(chk) {

    GetVirtualFarm(chk);

    filterWithOrganicFarms = document.getElementById('chkOrganic').checked;

    //if (chk == true) {
    //    alert("Implement here filtering by virtual visits");
    //}

    mapFilter(key);
}
function GetVirtualFarm(chk) {
    if (chk == true) {
        if (window.location.host == "skole.lf.dk")
            xhReq.open("GET", "https://medlem.lf.dk/API/getVirtualFarms", false);
        else
            xhReq.open("GET", "https://medlem.webapptest.lf.dk/API/getVirtualFarms", false);
        xhReq.onreadystatechange = function (oEvent) {
            if (xhReq.readyState === 4) {
                if (xhReq.status === 200) {
                    virtualFarms = JSON.parse(xhReq.responseText);
                } else {
                    console.log("Error", xhReq.statusText);
                }
            }
        };

        xhReq.send(null);
    }
    else {
        virtualFarms = [];
    }
}

function GetDisabledFarms(chk) {
    if (window.location.host == "skole.lf.dk")
        xhReq.open("GET", "https://medlem.lf.dk/API/GetDisabledFarms", false);
    else
        xhReq.open("GET", "https://medlem.webapptest.lf.dk/API/GetDisabledFarms", false);
    xhReq.onreadystatechange = function (oEvent) {
        if (xhReq.readyState === 4) {
            if (xhReq.status === 200) {
                disabledFarms = JSON.parse(xhReq.responseText);
            } else {
                console.log("Error", xhReq.statusText);
            }
        }
    };

    xhReq.send(null);
}



// Sets the map on all markers in the array.
function setMapOnAll(map) {
    for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
    }
}

// Removes the markers from the map, but keeps them in the array.
function clearMarkers() {
    markerCluster.setMap(null);
    setMapOnAll(null);
}

// Deletes all markers in the array by removing references to them.
function deleteMarkers() {
    clearMarkers();
    markers = [];
    markerCluster.clearMarkers();
    markers = [];
}

function initMap() {
    try {
        GetDisabledFarms();
    } catch (err) {
        console.error(err);
    }
    // console.log('initMap loaded, number of locations ' + updatedLocations.length);

    map = new google.maps.Map(document.getElementById('map'), {
        zoom: getZoom,
        center: { lat: getLat, lng: getLng },
        mapTypeId: 'terrain',
        mapTypeControl: false
    });


    if (disabledFarms.length > 0) {
        var allItems = locations;
        locations = [];

        jQuery.each(allItems, function () {
            if (disabledFarms.indexOf(parseInt(this[11])) < 1) {
                locations.push(this);
            }
        });
        updatedLocations = locations;
    }
    //updatedLocations



    markers = updatedLocations.map(function (location, i) {
        //if (location[0].indexOf("Mannerup") > -1) {
        //     debugger;

        image = '/assets/skole/images/maps/mappin_gaard.png';
        if (location[3].indexOf('Virksomhed') !== -1) {
            image = '/assets/skole/images/maps/mappin_virksomhed.png';
        }


        var marker = new google.maps.Marker({
            position: { lat: location[1], lng: location[2] },
            icon: image,
            title: ""
        });


        var boxOrganic = "";
        if (location[8]) {
            if (location[8] == '1') {
                boxOrganic = "<img class=\"organic-icon\" src=\"/assets/skole/images/logo-organic.jpg\">";// "<div class='bubbleorganic'></div>";				
            }
        }
        var boxArla = "";
        //console.log(location[7] + "x");
        if (location[7]) {
            if (location[7].indexOf('{FCE1182E-6610-42BE-ABD6-14082F02C31A}') >= 0) {
                boxArla = "<img class=\"arla-icon\" src=\"/assets/skole/images/logo-arla.png\">";// "<div class='bubblearla'></div>";
                //url(/assets/skole/images/logo-arla.png)
            }
        }
        var icons = "";
		/*if (location[10]) {
			var ic=location[10].split(',');
			for(var i=0;i<ic.length;i++)
			{
				if (ic[i].indexOf('arla.jpg') < 0)								
                   icons =icons+"<img class=\"delivery-icon\" src=\""+ic[i]+"\">";
			}
        }*/

        var infowindow = new google.maps.InfoWindow({
            content:

                '<div class="bubble-text container"><b><a href="' + location[6] + '">' +
                location[0] +
                '</a></b>' +

                '<p> Her producerer vi ' + location[4] + '</p>' +
                '<div class="row">' +
                '<div class="col-8"><span class=\"delivery-icons-list\">' + boxOrganic + boxArla + icons + '</span></div>' +
                '<div class="col-4"><a class="btn btn-primary btn-sm pull-right" href="' + location[6] + '">Se mere</a></div>' +
                '</div>',
        });

        marker.addListener('click', function () {
            if (activeInfoWindow) { activeInfoWindow.close(); }

            infowindow.open(map, marker);

            activeInfoWindow = infowindow;
        });
        return marker;
        //}
    });



    // Add a marker clusterer to manage the markers.
    markerCluster = new MarkerClusterer(map, markers, {
        imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        title: 'Klik for zoom',
        maxZoom: 6,
        ignoreHidden: true
    });

}


function revealMore(el) {
    jQuery(el).hide();
    jQuery("#map-more-text").show();
}

function setLoc(lat, lng, zoom) {
    var center = new google.maps.LatLng(lat, lng);
    map.panTo(center);
    map.setZoom(8);
    //if (zoom) {
    //    map.setZoom(zoom)
    //}

    //if (isBigMapOpen) {
    //    map.panTo(center);
    //}
    //else {
    //    map.panTo(center);
    //    $("#search-box #search-box-header").click();
    //}
    //window.scrollTo(0, 0)
}
